import { Model, Attr, HasMany, BelongsTo } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import Customer from './Customer';
import OrganizationUser from './OrganizationUser';
import CustomerAddress from './CustomerAddress';
import CustomerEmail from './CustomerEmail';
import CustomerPhone from './CustomerPhone';
import CustomerTag from './CustomerTag';
import CustomerNote from './CustomerNote';
import DatasetMessage from './DatasetMessage';
import DatasetCategory from './DatasetCategory';
import Invoice from './Invoice';
import Payment from './Payment';
import ImportModel from './ImportModel';
import ImportInvoice from './ImportInvoice';
import ImportCustomer from './ImportCustomer';
import Agreement from './Agreement';
import Conversation from './Conversation';
import User from './User';
import Organization from './Organization';

@Model({ keyCase: { server: "snake", client: "snake" } })
export default class Notification extends ApplicationRecord {
  static jsonapiType = "notifications";

  /* Attributes */
  @Attr({ persist: false }) id!: string;
  @Attr() user_id!: string;
  @Attr() organization_id!: string;
  @Attr() customer_id!: string;
  @Attr() agreement_id!: string;
  @Attr() payment_id!: string;
  @Attr() notification_type!: string;
  @Attr() content!: string;
  @Attr() link!: string;
  @Attr() remind_at!: string;
  @Attr() read!: boolean;
  @Attr() delegated_to_id!: string;

  @Attr() organization_name!: string;

  @Attr({ persist: false }) created_at!: string;
  @Attr({ persist: false }) updated_at!: string;

  @BelongsTo() user!: User;
  @BelongsTo() organization!: Organization;
  @BelongsTo() customer!: Customer;
  @BelongsTo() agreement!: Agreement;
  @BelongsTo() payment!: Payment;
}
