import { Model, Attr, BelongsTo, HasMany } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import Organization from './Organization';
import Customer from './Customer';
import User from './User';
import Message from './Message';
import Integration from './Integration';

@Model({ keyCase: { server: "snake", client: "snake" } })
export default class Conversation extends ApplicationRecord {
  static jsonapiType = 'conversations'

  /* Attributes */
  @Attr({ persist: false }) id!: string;
  @Attr({ persist: false }) organization_id!: string;
  @Attr({ persist: false }) customer_id!: string;
  @Attr({ persist: false }) user_id!: string;
  @Attr() integration_id!: string;

  @Attr() customer_from_address!: string;
  @Attr() customer_to_address!: string;
  @Attr() last_message_at!: string;
  @Attr() last_message_line!: string;
  @Attr() read!: boolean;

  @Attr() generic!: string;

  @Attr({ persist: false }) created_at!: string;
  @Attr({ persist: false }) updated_at!: string;


  /* Associations */
  @BelongsTo() organization!: Organization;
  @BelongsTo() customer!: Customer;
  @BelongsTo() user!: User;
  @BelongsTo() integration!: Integration;
  @HasMany(Message) messages!: Message[];
}
