import React from 'react';


type Props = {
  pageTitle: string;
  children?: any;
  breadcrumb?: any;
  style?: React.CSSProperties;
}

const PageHeader = (props: Props) => {
  return (
    <div className="page-header page-header--no-page-tabs" style={props.style}>
      <div className="bx--grid">
        <div className="bx--row">
          <div className="bx--col-lg-12">
            {props.breadcrumb}
            <h1 className="page-title">{props.pageTitle}</h1>
          </div>
        </div>
      </div>
      {props.children}
    </div>
  )
}

export default PageHeader;
