import React from 'react'
import { inject, observer } from 'mobx-react'
import { RootStore } from '../stores'

interface Props {
  role: '*' | 'user' | 'admin';
  rootStore?: RootStore;
  children: any;
}

@inject('rootStore')
@observer
export default class RoleBasedView extends React.Component<Props> {
  render() {
    const { rootStore, children, role } = this.props;
    const currentOrganizationUser = rootStore!.sessionStore.currentUser!.organization_users.find(
      os => os.organization.id === rootStore!.organizationStore.currentOrganizationId);

    // When not found the OrganizationUser
    if (typeof currentOrganizationUser === "undefined") {
      if (role === "*") {
        return children;
      } else {
        return null;
      }
    } else {
      if (role === "*") {
        return children
      } else if (role === currentOrganizationUser.role) {
        return children
      } else {
        return null;
      }
    }
  }
}
