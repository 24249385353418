import { observable, action, computed, when, autorun, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import auth0 from 'auth0-js'
import { RootStore } from './index'
import { browserHistory } from '../routes'
import ApplicationRecord from '../models/ApplicationRecord';
import User from '../models/User';

export default class SessionStore {
  rootStore: RootStore;
  auth0: auth0.WebAuth;

  @persist @observable currentUserId: string | null = null;
  @persist('object') @observable currentUser: User | null = null;
  @persist @observable accessToken: string | undefined = undefined;
  @persist @observable expiresAt: number = 0;
  @observable.ref authErrors: any = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
      redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI as string,
      responseType: 'token',
      scope: 'openid',
      audience: 'https://api.acerteja.com.br'
    });

    /**
     * Update ApplicationRecord Token
     */
    autorun(async () => {
      ApplicationRecord.jwt = this.accessToken;
      this.rootStore.api.defaults.headers.common['Authorization'] = `Bearer ${this.accessToken}`;
    });

    /**
     * Auth0: Automatic Token Renew
     *
     * This method will ensure that a token still alive when the user is active,
     * trying the silent authentication on Auth0 API.
     */
    when(
      () => {
        if (!this.isAuthenticated) return false;
        let timeRemainingInSeconds = Math.floor((this.expiresAt - new Date().getTime()) / 1000);
        if (timeRemainingInSeconds >= 50 && timeRemainingInSeconds <= 10000) {
          this.auth0.checkSession({}, (err: any, authResult: any) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
              this.setSession(authResult);
            } else if (err) {
              this.logout();
            }
          });
        }
        return true;
      }
    );

    /**
     * Update User
     *
     * Each page reload, we will fetch the new User data and update
     * the persisted information.
     */
    when(
      () => this.isAuthenticated && this.currentUserId !== null,
      async () => {
        let currentUser = await User.includes({ organization_users: 'organization' }).find(this.currentUserId!);
        this.currentUser = currentUser.data;
      }
    );
  }

  @action.bound login() {
    this.auth0.authorize({ prompt: 'login' });
  }

  @action.bound logout() {
    this.currentUserId = null;
    this.currentUser = null;
    this.accessToken = undefined;
    this.expiresAt = 0;
    this.authErrors = null;
    this.rootStore.organizationStore.currentOrganizationId = null;
    browserHistory.push('/signin');
  }

  @action.bound handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken) {
        this.setSession(authResult);
      } else if (err) {
        this.authErrors = err;
        browserHistory.push('/signin', { ...err });
      }
    });
  }

  @action.bound async setSession(authResult: any) {
    ApplicationRecord.jwt = authResult.accessToken;
    this.rootStore.api.defaults.headers.common['Authorization'] = `Bearer ${authResult.accessToken}`;

    // Load the User from API
    let response = await this.rootStore.api.get('users/me');
    let { data } = response.data;
    let currentUser = await User.includes({ organization_users: 'organization' }).find(data.id);


    // Update Data
    runInAction(() => {
      this.accessToken = authResult.accessToken;
      this.expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
      this.currentUserId = data.id;
      this.currentUser = currentUser.data;
      browserHistory.push('/dashboard');
    });
  }

  @computed get isAuthenticated() {
    return new Date().getTime() < this.expiresAt;
  }
}
