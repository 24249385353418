import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import {
  InlineNotification,
  InlineLoading,
  Button,
  NotificationActionButton,
  Checkbox,
} from "carbon-components-react";
import Notification from "../../models/Notification";
import OverflowMenuHorizontal16 from "@carbon/icons-react/es/overflow-menu--horizontal/16";
import moment from "moment";
import { notification, Icon, Tabs } from "antd";
import {
  ActionCableProvider,
  ActionCableConsumer,
} from "react-actioncable-provider";
import { Link } from "react-router-dom";
import {
  forwardRef,
  useImperativeHandle,
  ForwardRefExoticComponent,
  RefAttributes,
  Ref,
  useRef,
} from "react";
import { inject, observer } from "mobx-react";
import User from "../../models/User";
import { userInfo } from "os";

export type Handle<T> = T extends ForwardRefExoticComponent<
  RefAttributes<infer T2>
>
  ? T2
  : never;

interface Props {
  user: User;
  organizationId: string;
  updateNotificationCount: any;
}

@inject("rootStore")
@observer
class NotificationLink extends React.Component<any> {
  render() {
    return (
      <Link
        className="bx--link"
        to={this.props.to}
        onClick={() => {
          this.props.rootStore!.organizationStore.setCurrentOrganization(
            this.props.organizationId,
            false
          );
        }}
      >
        {this.props.children}
      </Link>
    );
  }
}

const NotificationsBox = forwardRef(
  (
    props: { q: any; type: string; delegated: boolean; children?: any },
    ref: Ref<any>
  ) => {
    const { q, type, delegated } = props;
    const [isLoading, setIsLoading] = useState(false);

    useImperativeHandle(ref, () => ({ IngestWebhookEvent }));
    function IngestWebhookEvent(notification: Notification) {
      setTasks((previousTasks) =>
        [
          ...previousTasks.filter((t) => t.id !== notification.id),
          notification,
        ].sort(
          (a, b) => moment(b.remind_at).unix() - moment(a.remind_at).unix()
        )
      );
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [totalEntries, setTotalEntries] = useState(0);
    const [tasks, setTasks] = useState<Notification[]>([]);

    const fetchTasks = async (page: number = 1) => {
      const resources = await q.per(10).page(page).all();
      setTotalEntries(resources.meta.stats.total.count);
      setCurrentPage(page);
      setIsLoading(false);
      setTasks((previousTasks) => [...previousTasks, ...resources.data]);
    };

    useEffect(() => {
      setIsLoading(true);
      fetchTasks(currentPage);
    }, [currentPage]);

    const setNotificationAsRead = async (
      task: Notification,
      notificationId: string,
      val: boolean = true
    ) => {
      setTasks(
        tasks.map((t) => {
          if (t === task) t.read = val;
          return t;
        })
      );

      try {
        await task.save();
      } catch {
        notification.error({
          message: "Erro",
          description: "Houve um erro para atualizar este item",
        });

        // Undo
        setTasks(
          tasks.map((t) => {
            if (t === task) t.read = !val;
            return t;
          })
        );
      }
    };

    const loadNextPage = () => {
      setCurrentPage((previousPage) => previousPage + 1);
    };

    console.log(tasks);

    return (
      <>
        {tasks.map((task: Notification) => (
          <InlineNotification
            kind="info"
            key={task.id}
            title={
              type === "task"
                ? moment(task.remind_at).format("DD/MM")
                : moment(task.created_at).format("DD/MM")
            }
            subtitle={
              <div>
                <p>{task.content}</p>
                <p>
                  <span className="notification-small-txt">
                    {task.organization_name}
                  </span>
                  <NotificationLink
                    to={`/person/${task.customer_id}`}
                    organizationId={task.organization_id}
                  >
                    Visualizar
                  </NotificationLink>
                </p>
              </div>
            }
            hideCloseButton={type === "task" ? true : false}
            onCloseButtonClick={() => {
              setNotificationAsRead(task, task.id);
            }}
          >
            {type === "task" && (
              <div style={{ marginLeft: "auto" }}>
                {task.delegated_to_id && !delegated ? (
                  "Em Progresso"
                ) : (
                  <Checkbox
                    id={task.id}
                    defaultChecked={task.read}
                    hideLabel={true}
                    labelText="Concluir"
                    onChange={() =>
                      setNotificationAsRead(task, task.id, !task.read)
                    }
                  />
                )}
              </div>
            )}
          </InlineNotification>
        ))}

        {isLoading ? (
          <InlineLoading
            style={{ marginLeft: "1rem" }}
            description="Carregando..."
            status="active"
          />
        ) : (
          !delegated && (
            <Button
              kind="tertiary"
              size="small"
              renderIcon={OverflowMenuHorizontal16}
              onClick={() => loadNextPage()}
              disabled={currentPage * 10 > totalEntries}
            >
              Carregar mais
            </Button>
          )
        )}
      </>
    );
  }
);

const Notifications = ({
  user,
  organizationId,
  updateNotificationCount,
}: Props) => {
  let tasksHandle = useRef();
  let infoHandle = useRef();
  let outerTasksHandle = useRef();

  const handleWebsocketData = (data: any) => {
    // Get the Notification
    if (data.type === "notification") {
      let parsedData = JSON.parse(data.data);
      parsedData.jsonResponse = parsedData;
      let notification = Notification.fromJsonapi(parsedData.data, parsedData);
      if (notification.notification_type === "info") {
        (infoHandle.current as any).IngestWebhookEvent(notification);
        (outerTasksHandle.current as any).IngestWebhookEvent(notification);
      } else {
        (tasksHandle.current as any).IngestWebhookEvent(notification);
      }
    } else if (data.type === "badge") {
      updateNotificationCount(data.value);
    }
  };

  return (
    <ActionCableProvider url={process.env.REACT_APP_WEBSOCKET_ENDPOINT}>
      <ActionCableConsumer
        channel={{
          channel: "NotificationChannel",
          user_id: user.id,
        }}
        onConnected={() => console.log("online")}
        onDisconnected={() => console.log("disconnected")}
        onRejected={() => console.log("rejected")}
        onReceived={handleWebsocketData}
      >
        <div className={styles.container}>
          <div className={styles.subcontainer}>
            <h3 className={styles.subcontainer_title}>Tarefas</h3>

            <NotificationsBox
              ref={outerTasksHandle}
              type="task"
              q={Notification.where({
                notification_type: "task",
                delegated_to_id: user.id,
                read: false,
              })
                .order({ remind_at: "asc" })
                .stats({ total: "count" })}
              delegated={true}
            />
            <NotificationsBox
              ref={tasksHandle}
              type="task"
              q={Notification.where({
                notification_type: "task",
                user_id: user.id,
                read: false,
              })
                .order({ remind_at: "asc" })
                .stats({ total: "count" })}
              delegated={false}
            />
          </div>

          <div className={styles.subcontainer}>
            <h3 className={styles.subcontainer_title}>Notificações</h3>
            <NotificationsBox
              ref={infoHandle}
              type="info"
              q={Notification.where({
                notification_type: "info",
                read: false,
                user_id: user.id,
              })
                .order({ created_at: "desc" })
                .stats({ total: "count" })}
              delegated={false}
            />
          </div>
        </div>
      </ActionCableConsumer>
    </ActionCableProvider>
  );
};

export default Notifications;
