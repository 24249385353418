import React from 'react';
import { observable, action, computed, when } from 'mobx';
import { persist } from 'mobx-persist';
import { RootStore } from './index';
import { notification, Icon } from 'antd';
import Organization from '../models/Organization';
import OrganizationGroup from '../models/OrganizationGroup';
import { browserHistory } from '../routes';

export default class OrganizationStore {
  rootStore: RootStore;

  @persist @observable currentOrganizationId: string | null = null;
  @observable organizations: { [s: string]: Organization } = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    when(
      () => this.rootStore.sessionStore.isAuthenticated,
      () => this.fetchOrganizations(),
      );
  }
  
  @action
  async fetchOrganizations() {
    let req = await Organization.per(100).all();
    req.data.forEach((org: Organization) => this.organizations[org.id] = org);
  }

  @action.bound
  setCurrentOrganization(organizationId: string, shouldRedirect: boolean = true) {
    if (organizationId in this.organizations)
    {
      this.currentOrganizationId = organizationId;
      if (shouldRedirect) browserHistory.push("/");
      notification.open({
        message: 'Organização alterada',
        description: 'Você está visualizando uma nova organização.',
        icon: React.createElement(Icon, {type: 'alert'})
      });
    }

  }

  @computed get currentOrganization() {
    if (this.currentOrganizationId === null)
      return null;

    if (!(this.currentOrganizationId in this.organizations))
      return null;
    return this.organizations[this.currentOrganizationId];
  }
}
