import SessionStore from './SessionStore'
import OrganizationStore from './OrganizationStore';
import OrganizationGroupStore from './OrganizationGroupStore';
import AxiosApi from '../services/api';
import { AxiosInstance } from 'axios';

export class RootStore {
  api: AxiosInstance;
  sessionStore: SessionStore;
  organizationStore: OrganizationStore;
  organizationGroupStore: OrganizationGroupStore;

  constructor() {
    this.api = AxiosApi;
    this.sessionStore = new SessionStore(this)
    this.organizationStore = new OrganizationStore(this);
    this.organizationGroupStore = new OrganizationGroupStore(this);
  }
}

export default new RootStore();
