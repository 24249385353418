import React from 'react';
import LayoutHeader from '../LayoutHeader/LayoutHeader';

const Layout = (props: any) => {
  return (
    <div>
      <LayoutHeader />
      <div className="container">
        {props.children}
      </div>
    </div>
  )
}

export default Layout;
