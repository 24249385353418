import { Model, Attr, BelongsTo, HasMany } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import Organization from './Organization';
import Customer from './Customer';
import User from './User';
import MessageAttachment from './MessageAttachment';
import Conversation from './Conversation';
import Workflow from './Workflow';
import Campaign from './Campaign';
import CampaignAction from './CampaignAction';

@Model({ keyCase: { server: "snake", client: "snake" } })
export default class Message extends ApplicationRecord {
  static jsonapiType = 'messages'

  /* Attributes */
  @Attr({ persist: false }) id!: string;
  @Attr({ persist: false }) conversation_id!: string;
  @Attr({ persist: false }) user_id!: string;
  @Attr({ persist: false }) workflow_id!: string;
  @Attr({ persist: false }) campaign_id!: string;
  @Attr({ persist: false }) campaign_action_id!: string;

  @Attr() message_channel_type!: string;
  @Attr() from!: string;
  @Attr() to!: string;
  @Attr() status!: string;
  @Attr() content!: string;
  @Attr() whatsapp_content_outside_window!: string;
  @Attr() email_subject!: string;
  @Attr() email_reply_to_header!: string;
  @Attr() email_clicked!: boolean;
  @Attr() email_opened!: boolean;
  @Attr() email_message_id!: string;

  @Attr() sent_from_bot!: boolean;

  @Attr({ persist: false }) created_at!: string;
  @Attr({ persist: false }) updated_at!: string;

  get friendly_channel(): string {
    let mapChannelType: any = {
      "sms": "SMS",
      "whatsapp": "WhatsApp Business",
      "email": "E-mail",
      "whatsapp_domestic": "WhatsApp Doméstico"
    };
    if (this.message_channel_type in mapChannelType)
      return mapChannelType[this.message_channel_type];
    return ""
  }

  get friendly_status(): string {
    let friendlyStatus: any = {
      "pending": "Pendente",
      "queued": "Em Fila",
      "accepted": "Aceita",
      "sent": "Enviada",
      "delivered": "Entregue",
      "receiving": "Recebendo",
      "received": "Recebida",
      "read": "Lida",
      "failed": "Com Erro",
      "undelivered": "Não Enviada",
    };

    if (this.status in friendlyStatus)
      return friendlyStatus[this.status];
    return "Desconhecido"
  }

  get is_author(): boolean {
    return this.user_id !== null || this.campaign_action_id !== null ||
           this.campaign_id !== null || this.workflow_id !== null ||
           this.sent_from_bot === true;
  }

  /* Associations */
  @BelongsTo() conversation!: Conversation;
  @BelongsTo() user!: User;
  @BelongsTo() workflow!: Workflow;
  @BelongsTo() campaign!: Campaign;
  @BelongsTo() campaign_action!: CampaignAction;
  @HasMany() message_attachments!: MessageAttachment[];
}
