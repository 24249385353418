import React from 'react';
import { observable, action, computed, when } from 'mobx';
import { persist } from 'mobx-persist';
import { RootStore } from './index';
import OrganizationGroup from '../models/OrganizationGroup'

export default class OrganizationGroupStore {
  rootStore: RootStore;

  @persist @observable currentGroupId: string | null = null;
  @observable organizationGroups: { [s: string]: OrganizationGroup } = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    when(
      () => this.rootStore.sessionStore.isAuthenticated,
      () => this.fetchOrganizationsGroup(),
      );
  }

  @action
  async fetchOrganizationsGroup() {
    let req = await OrganizationGroup.all();
    req.data.forEach((group: OrganizationGroup) => this.organizationGroups[group.id] = group);
  }

  @action.bound
  setCurrentOrganizationGroup(groupId: string, shouldRedirect: boolean = true) {
    if (groupId in this.organizationGroups)
    {
      this.currentGroupId = groupId;
    }
  }

  @computed get currentOrganization() {
    if (this.currentGroupId === null)
      return null;

    if (!(this.currentGroupId in this.organizationGroups))
      return null;
    return this.organizationGroups[this.currentGroupId];
  }
}
