import React, { Component } from 'react'
import styled from 'styled-components'
import { Loading } from 'carbon-components-react'

const PageCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 200px;
  height: 200px;
`

export default class PageLoading extends Component {
  render() {
    return (
      <PageCenter>
        <Loading active={true} withOverlay={false} description="Carregando..." style={{ width: 200, height: 200 }} />
      </PageCenter>
    )
  }
}
