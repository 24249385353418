import React, { Component } from "react";
import {
  Header,
  HeaderNavigation,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderPanel
} from "carbon-components-react";
import Divider from "../../components/CarbonFix/Divider";
import HeaderMenuItem from "../../components/CarbonFix/HeaderMenuItem";
import HeaderMenu from "../../components/CarbonFix/HeaderMenu";
import HeaderName from "../../components/CarbonFix/HeaderName";
import { observer, inject } from "mobx-react";
import { RootStore } from "../../stores";
import Organization from "../../models/Organization";
import CompanyHeaderMenuItem from "../../components/CarbonFix/CompanyHeaderMenuItem";
import RoleBasedView from "../../components/RoleBasedView";
import Notification20 from "@carbon/icons-react/lib/notification/20";
import Group32 from "@carbon/icons-react/lib/user/32";
import Notifications from "../Notification";
import OrganizationGroup from "../../models/OrganizationGroup";

type Props = {
  rootStore?: RootStore;
};
type State = {
  panels: {
    options: boolean;
    notifications: boolean;
    search: boolean;
  };

  unreadNotifications: number;
  groupSelected: any;
};
type PanelSelector = "options" | "notifications" | "search";

@inject("rootStore")
@observer
export default class DashboardHeader extends Component<Props, State> {
  state = {
    panels: {
      options: false,
      notifications: false,
      search: false
    },
    unreadNotifications: 0,
    groupSelected: {}
  };

  togglePanel(panel: PanelSelector) {
    const { panels, ...prevState } = this.state;
    this.setState({
      ...prevState,
      panels: {
        options: false,
        notifications: false,
        search: false,
        [panel]: !this.state.panels[panel]
      }
    });
  }

  handleOrganizationClick(organizationIdentifier: string) {
    this.props.rootStore!.organizationStore.setCurrentOrganization(
      organizationIdentifier
    );
  }

  handleGroupClick(group: OrganizationGroup) {
    this.setState({groupSelected: group})
  }

  updateNotificationCount = (count: number) => {
    this.setState({ unreadNotifications: count });
  }

  render() {
    const groupedOrganizations: {[s:string]: Organization[]} = {};
    const orphanOrganizations = [];
    
    for (const [id, organization] of Object.entries(this.props.rootStore!.organizationStore.organizations)){
      let groupId = organization.organization_group_id

      if(groupId !== null){
        if(!(groupId in groupedOrganizations)){
          groupedOrganizations[groupId] = []
        }
        groupedOrganizations[groupId].push(organization)
      } else {
        orphanOrganizations.push(organization);
      }
    }

    const groupMenuLabel = Object.values(
      this.props.rootStore!.organizationGroupStore.organizationGroups
    ).map((group: OrganizationGroup, index: number) => {
      return (
        <CompanyHeaderMenuItem
          key={group.id}
          identifier={group.id}
          onClick={() =>
            this.handleGroupClick(group)
          }
          aria-label="Link 3"
        >
          + {group.name}
        </CompanyHeaderMenuItem>
      );
    })

    const organizationsMenu = Object.values(orphanOrganizations).map((organization) => {
      return (
        <CompanyHeaderMenuItem
          key={organization.id}
          identifier={organization.id}
          onClick={(organizationIdentifier: string) =>
            this.handleOrganizationClick(organizationIdentifier)
          }
        >
          {organization.name}
        </CompanyHeaderMenuItem>
      );
    })

    const currentOrganizationName =
      this.props.rootStore!.organizationStore.currentOrganization === null
        ? "Organização"
        : this.props.rootStore!.organizationStore.currentOrganization.name;

    var adminMenu: any = null;
    if (
      this.props.rootStore!.sessionStore.currentUser!.organization_users.some(
        i => i.role === "admin"
      )
    ) {
      adminMenu = (
        <>
          <HeaderMenuItem to="/organizations">
            Gerenciar Organizações
          </HeaderMenuItem>
          <HeaderMenuItem to="/users">Gerenciar Usuários</HeaderMenuItem>
          <Divider />
        </>
      );
    }

    return (
      <>
        <Header aria-label="Top Menu">
          <HeaderName to="/dashboard" prefix="Acerte">
            Já
          </HeaderName>

          <HeaderNavigation aria-label="AcerteJá [Platforma]">
            <HeaderMenuItem to="/dashboard">Principal</HeaderMenuItem>
            <HeaderMenuItem to="/people">Pessoas</HeaderMenuItem>
            <HeaderMenuItem to="/livechat">Atendimento</HeaderMenuItem>
            <RoleBasedView role="admin">
              <HeaderMenuItem to="/campaigns">Campanhas</HeaderMenuItem>
              <HeaderMenuItem to="/imports">Importações</HeaderMenuItem>
              <HeaderMenu menuLinkName="Automação" aria-label="Link 4">
                <HeaderMenuItem to="/workflows">
                  Fluxos de Conversação
                </HeaderMenuItem>
                <HeaderMenuItem to="/dashboard">Regras</HeaderMenuItem>
                <HeaderMenuItem to="/datasets">Treinamento</HeaderMenuItem>
                <Divider />
                <HeaderMenuItem to="/integrations">Integrações</HeaderMenuItem>
              </HeaderMenu>
            </RoleBasedView>
            <HeaderMenuItem to="/dashboard/reports">Relatórios</HeaderMenuItem>
          </HeaderNavigation>

          <HeaderGlobalBar>
            {this.state.groupSelected instanceof OrganizationGroup ? 
            <HeaderMenu
              menuLinkName={this.state.groupSelected.name}
              openToRight={true}
            >
              {groupedOrganizations[this.state.groupSelected.id].map((organization) => {
                return (
                  <CompanyHeaderMenuItem
                  key={organization.id}
                  identifier={organization.id}
                  onClick={(organizationIdentifier: string) =>
                    this.handleOrganizationClick(organizationIdentifier)
                  }
                  >
                    {organization.name}
                  </CompanyHeaderMenuItem>
                );
              })}
              <Divider />
              <HeaderMenuItem to='#' onClick={() => this.setState({groupSelected: {}})}>Voltar</HeaderMenuItem>
              <Divider />
              {adminMenu}
            </HeaderMenu>
          :
            <HeaderMenu
            menuLinkName={currentOrganizationName}
            aria-label="Link 4"
            openToRight={true}
            >
              {groupMenuLabel}
              {organizationsMenu}
              <Divider />
              {adminMenu}
              <HeaderMenuItem to="/signout">Sair</HeaderMenuItem>
            </HeaderMenu>
            }
            <HeaderGlobalAction aria-label="Notifications" onClick={() => this.togglePanel('notifications')}>
              <Notification20 />
              <span className="notification-menu-badge">
                {this.state.unreadNotifications}
              </span>
            </HeaderGlobalAction>
          </HeaderGlobalBar>

          <HeaderPanel expanded={this.state.panels['notifications']} aria-label="Notificações">
            <Notifications
              user={this.props.rootStore!.sessionStore.currentUser!}
              organizationId={this.props.rootStore!.organizationStore.currentOrganizationId!}
              updateNotificationCount={this.updateNotificationCount}
            />
          </HeaderPanel>
        </Header>
      </>
    );
  }
}
