import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { inject, observer } from "mobx-react";

import Layout from "./containers/Layout/Layout";
import PageLoading from "./components/PageLoading";
import SelectOrganization from "./containers/Dashboard/SelectOrganization";
import Exception from "./components/Errors/Exception";

// Browser History for location manipulation
export const browserHistory = createBrowserHistory();

@inject("rootStore")
@observer
export class Authenticated extends Component<any, any> {
  render() {
    const { rootStore, children } = this.props;
    if (rootStore.sessionStore.isAuthenticated) return children;
    rootStore.sessionStore.logout();
    return null;
  }
}

@inject("rootStore")
@observer
export class OrganizationBasedRoute extends Component<any, {}> {
  render() {
    const { rootStore, component: Component, ...rest } = this.props;
    let currentOrganizationId =
      rootStore.organizationStore.currentOrganizationId;
    if (currentOrganizationId === null) {
      return <SelectOrganization />;
    } else {
      return (
        <Route
          {...rest}
          render={(props: any) => (
            <Component {...props} organizationId={currentOrganizationId} />
          )}
        />
      );
    }
  }
}

@inject("rootStore")
@observer
export default class Routes extends Component {
  render() {
    return (
      <Router history={browserHistory}>
        <Suspense fallback={<PageLoading />}>
          <Switch>
            {/* Authentication Routes */}
            <Route exact path="/" component={() => <Redirect to="/signin" />} />
            <Route
              exact
              path="/signin"
              component={lazy(() =>
                import("./containers/Authentication/SignIn")
              )}
            />
            <Route
              exact
              path="/signin/auth0_callback"
              component={lazy(() =>
                import("./containers/Authentication/Auth0Callback")
              )}
            />
            <Route
              exact
              path="/signout"
              component={lazy(() =>
                import("./containers/Authentication/SignOut")
              )}
            />

            {/* Customer Accesible Routes */}
            <Route
              exact
              path="/consumidor/acordos/:id"
              component={lazy(() =>
                import("./containers/PublicCustomer/Agreement")
              )}
            />

            {/* Authenticated */}
            <Authenticated>
              <Layout>
                <Switch>
                  {/* Organization Routes */}
                  <Route
                    exact
                    path="/organizations"
                    component={lazy(() =>
                      import("./containers/Organization/List")
                    )}
                  />

                  {/* User Routes */}
                  <Route
                    exact
                    path="/users"
                    component={lazy(() => import("./containers/Users/List"))}
                  />

                  {/* Dashboard Routes */}
                  <OrganizationBasedRoute
                    exact
                    path="/dashboard"
                    component={lazy(() =>
                      import("./containers/Dashboard/View")
                    )}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/dashboard/reports"
                    component={lazy(() =>
                      import("./containers/Dashboard/Reports")
                    )}
                  />

                  {/* People Routes */}
                  <OrganizationBasedRoute
                    exact
                    path="/people"
                    component={lazy(() => import("./containers/Customer/List"))}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/people/new"
                    component={lazy(() => import("./containers/Customer/New"))}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/person/:id"
                    component={lazy(() => import("./containers/Customer/Show"))}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/person/:id/profile"
                    component={lazy(() =>
                      import("./containers/Customer/ShowProfile")
                    )}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/person/:id/imports"
                    component={lazy(() =>
                      import("./containers/Customer/Imports/List")
                    )}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/person/:id/invoices"
                    component={lazy(() =>
                      import("./containers/Customer/Invoices/List")
                    )}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/person/:id/agreements"
                    component={lazy(() =>
                      import("./containers/Customer/Agreements/List")
                    )}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/person/:id/campaigns"
                    component={lazy(() =>
                      import("./containers/Customer/Campaigns/List")
                    )}
                  />

                  {/* Datasets Routes */}
                  <OrganizationBasedRoute
                    exact
                    path="/datasets"
                    component={lazy(() => import("./containers/Dataset/List"))}
                  />

                  {/* Workflows Routes */}
                  <OrganizationBasedRoute
                    exact
                    path="/workflows"
                    component={lazy(() => import("./containers/Workflow/List"))}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/workflows/new"
                    component={lazy(() => import("./containers/Workflow/New"))}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/workflows/:id/edit"
                    component={lazy(() => import("./containers/Workflow/Edit"))}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/workflows/archived"
                    component={lazy(() => import("./containers/Workflow/Archived"))}
                  />

                  {/* Import Routes */}
                  <OrganizationBasedRoute
                    exact
                    path="/imports"
                    component={lazy(() =>
                      import("./containers/DataImport/List")
                    )}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/imports/new"
                    component={lazy(() =>
                      import("./containers/DataImport/New")
                    )}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/imports/:id"
                    component={lazy(() =>
                      import("./containers/DataImport/Show")
                    )}
                  />

                  {/* Livechat */}
                  {/* <OrganizationBasedRoute
                    exact path='/livechat'
                    component={lazy(() => import('./containers/Livechat'))} /> */}
                  <Route
                    path="/livechat"
                    component={lazy(() =>
                      import("./containers/NewLivechat/Main")
                    )}
                  />

                  {/* Campaigns */}
                  <OrganizationBasedRoute
                    exact
                    path="/campaigns"
                    component={lazy(() => import("./containers/Campaign/List"))}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/campaigns/new"
                    component={lazy(() => import("./containers/Campaign/New"))}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/campaigns/archived"
                    component={lazy(() => import("./containers/Campaign/Archived"))}
                  />
                  <OrganizationBasedRoute
                    exact
                    path="/campaigns/:id"
                    component={lazy(() => import("./containers/Campaign/Show"))}
                  />

                  {/* Integrations */}
                  <OrganizationBasedRoute
                    path="/integrations"
                    component={lazy(() =>
                      import("./containers/Integrations/List")
                    )}
                  />

                  {/* Not Found */}
                  <Route
                    component={() => (
                      <Exception
                        title="Ocorreu um erro"
                        desc="Tente novamente mais tarde"
                      />
                    )}
                  />
                </Switch>
              </Layout>
            </Authenticated>
          </Switch>
        </Suspense>
      </Router>
    );
  }
}
