import React from 'react';
import { settings } from 'carbon-components';
const { prefix } = settings;

/* eslint-disable */
const CompanyHeaderMenuItem = (props: any) => {
  const { identifier, children, onClick, ...rest } = props;
  return (
    <li>
      <a href="#" className={`${prefix}--header__menu-item`} {...rest} role="menuitem" onClick={(e) => {
          e.preventDefault();
          onClick(identifier);
          return false;
        }}>
        <span className={`${prefix}--text-truncate--end`}>
          {children}
        </span>
      </a>
    </li>
  )
}
/* eslint-enable */

export default React.forwardRef((props: any, ref) => {
  return <CompanyHeaderMenuItem {...props} />;
});
