import styled from 'styled-components';

const Divider = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,0.65);
  background: #e8e8e8;
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin: 0;
`
export default Divider;
