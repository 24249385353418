import { Model, SpraypaintBase, MiddlewareStack } from 'spraypaint';
import rootStore from '../stores'

@Model({ keyCase: { server: "snake", client: "snake" } })
export default class ApplicationRecord extends SpraypaintBase {
  static baseUrl = process.env.REACT_APP_BASE_API as string;
  static apiNamespace = '/v1';
  static clientApplication = 'react-frontend-spraypaint';

  static generateAuthHeader(token: string) {
    return `Bearer ${token}`
  }
}

/**
 * Authorization Header Management
 */
ApplicationRecord.jwtStorage = false

/**
 * Check for Not Authenticated Response
 */
let middleware = new MiddlewareStack();
middleware.beforeFilters.push((url: any, options: any) => {
  options.headers['Accept-Language'] = 'pt-BR';
});
middleware.afterFilters.push((response: any, json: any) => {
  if (response.status === 401) {
    rootStore.sessionStore.logout();
  }
  response.errors = json.errors || [];
});
ApplicationRecord.middlewareStack = middleware
