import { Model, Attr, HasMany } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import OrganizationUser from './OrganizationUser';
import Conversation from './Conversation';

@Model({ keyCase: { server: "snake", client: "snake" } })
export default class User extends ApplicationRecord {
  static jsonapiType = 'users'

  /* Attributes */
  @Attr({ persist: false }) id!: string;
  @Attr() email!: string;
  @Attr() first_name!: string;
  @Attr() last_name!: string;
  @Attr() phone_number!: string;
  @Attr() time_zone!: string;
  @Attr({ persist: false }) created_at!: string;
  @Attr({ persist: false }) updated_at!: string;

  @Attr() password!: string;

  get full_name(): string {
    return `${this.first_name} ${this.last_name}`
  }

  /* Associations */
  @HasMany(OrganizationUser) organization_users!: OrganizationUser[];
  @HasMany(Conversation) conversations!: Conversation[];
}
