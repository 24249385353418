import React from 'react';
import { settings } from 'carbon-components';
import cx from 'classnames';
import ChevronDownGlyph from '@carbon/icons-react/lib/chevron--down';
const { prefix } = settings;

type Props = {
  focusRef?: any;
  tabIndex?: any;
  menuLinkName: string;
  openToRight?: boolean;
};

type State = {
  expanded: boolean;
  selectedIndex: number | null;
}

export default class HeaderMenu extends React.Component<Props, State> {
  state: Readonly<State> = {
    expanded: false,
    selectedIndex: null
  }

  public static defaultProps: Partial<Props> = {
    openToRight: false
  }

  items: any[];
  menuButtonRef: any;
  node: any;

  constructor(props: Props) {
    super(props);
    this.items = [];
  }

  handleOnClick = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }));
  };

  handleOnBlur = (event: any) => {
    // Rough guess for a blur event that is triggered outside of our menu or
    // menubar context
    if (!event.relatedTarget) {
      this.setState({ expanded: false, selectedIndex: null });
    }
  };

  handleMenuButtonRef = (node: any) => {
    if (this.props.focusRef) {
      this.props.focusRef(node);
    }
    this.menuButtonRef = node;
  };

  /**
   * Handles individual menuitem refs. We assign them to a class instance
   * property so that we can properly manage focus of our children.
   */
  handleItemRef = (index: number) => (node: any) => {
    this.items[index] = node;
  };

  _renderMenuItem = (item: any, index: number) => {
    if (item === null) return null;
    return React.cloneElement(item, {
      ref: this.handleItemRef(index),
      role: 'none',
    });
  };



  componentWillMount() {
    document.body.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.body.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e: any) => {
    if (this.node.contains(e.target)) {
      return;
    }

    this.setState(prevState => ({
      expanded: false,
    }));
  }

  render() {
    const className = cx(`${prefix}--header__submenu`);
    let headerMenuStyle: React.CSSProperties = {};
    if (this.props.openToRight) {
      headerMenuStyle['right'] = 0
      headerMenuStyle['left'] = 'unset'
    }

    /*eslint-disable */
    return (
      <li
        className={className}
        onBlur={this.handleOnBlur}
        ref={(node: any) => this.node = node}>
        <a
          aria-haspopup="menu"
          aria-expanded={this.state.expanded}
          onClick={this.handleOnClick}
          className={`${prefix}--header__menu-item ${prefix}--header__menu-title`}
          href="javascript:void(0)"
          ref={this.handleMenuButtonRef}
          role="menuitem"
          tabIndex={0}
          >
          {this.props.menuLinkName}
          <ChevronDownGlyph className={`${prefix}--header__menu-arrow`} />
        </a>
        <ul
          className={`${prefix}--header__menu`}
          role="menu"
          style={headerMenuStyle}>
          {React.Children.map(this.props.children, this._renderMenuItem)}
        </ul>
      </li>
    );
    /*eslint-enable */
  }
}
