import { Model, Attr, HasMany } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import Customer from './Customer';
import OrganizationUser from './OrganizationUser';
import CustomerAddress from './CustomerAddress';
import CustomerEmail from './CustomerEmail';
import CustomerPhone from './CustomerPhone';
import CustomerTag from './CustomerTag';
import CustomerNote from './CustomerNote';
import DatasetMessage from './DatasetMessage';
import DatasetCategory from './DatasetCategory';
import Invoice from './Invoice';
import Payment from './Payment';
import ImportModel from './ImportModel';
import ImportInvoice from './ImportInvoice';
import ImportCustomer from './ImportCustomer';
import Agreement from './Agreement';
import Conversation from './Conversation';

@Model({ keyCase: { server: "snake", client: "snake" } })
export default class Organization extends ApplicationRecord {
  static jsonapiType = 'organizations'

  /* Attributes */
  @Attr({ persist: false }) id!: string;
  @Attr() name!: string;
  @Attr({ persist: false }) slug!: string;

  @Attr() primary_email!: string;
  @Attr() phone_number!: string;

  @Attr() classifier_endpoint!: string;
  @Attr() iugu_available_payment_methods!: string;
  @Attr() organization_group_id!: string;

  @Attr({ persist: false }) created_at!: string;
  @Attr({ persist: false }) updated_at!: string;
  @Attr({ persist: false }) discarded_at!: string;

  /* Associations */
  @HasMany('Customer') customers!: Customer[];
  @HasMany('CustomerAddress') customer_addresses!: CustomerAddress[];
  @HasMany('CustomerEmail') customer_emails!: CustomerEmail[];
  @HasMany('CustomerPhone') customer_phones!: CustomerPhone[];
  @HasMany('CustomerTag') customer_tags!: CustomerTag[];
  @HasMany('CustomerNote') customer_notes!: CustomerNote[];
  @HasMany('DatasetMessage') dataset_messages!: DatasetMessage[];
  @HasMany('DatasetCategory') dataset_categories!: DatasetCategory[];
  @HasMany('Invoice') invoices!: Invoice[];
  @HasMany('Payment') payments!: Payment[];
  @HasMany('ImportModel') imports!: ImportModel[];
  @HasMany('ImportInvoice') import_invoices!: ImportInvoice[];
  @HasMany('ImportCustomer') import_customers!: ImportCustomer[];
  @HasMany('OrganizationUser') organization_users!: OrganizationUser[];
  @HasMany('Agreement') agreements!: Agreement[];
  @HasMany('Conversation') conversations!: Conversation[];
}
