/**
 * AcerteJa - Dashboard
 * created by Bruno Alano Medina
 *
 * React + MobX + JSON API (Graphite)
 */
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { create } from 'mobx-persist';
import store from './stores';
import Routes from './routes';
import { IntlProvider } from 'react-intl';
import { LocaleProvider, notification } from 'antd';
import pt_BR from 'antd/lib/locale-provider/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

// Stylesheets
import './scss/antd.min.css';
import './scss/index.scss';
// import '@ant-design-pro/dist/ant-design-pro.css';
import '@carbon/charts/style.css';

// Moment Locale
moment.locale('pt-br');

// Bugsnag
const bugsnagClient = bugsnag({
  apiKey: 'f3989a752f45743b0809e0244e2ff585',
  notifyReleaseStages: ['production'],
  releaseStage: process.env.NODE_ENV,
  logger: null
});
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

// Change AntD Notification to Account for TopBar
notification.config({
  placement: 'bottomRight',
  top: 56,
});

// MobX Hydrations using localStorage
const toHydrate = [['session', store.sessionStore], ['organization', store.organizationStore], ['organizationGroups', store.organizationGroupStore]];
const hydrations = toHydrate.map((storeTuple: any) => {
  const [storeName, store] = storeTuple;
  const hydrate = create();
  return hydrate(storeName, store);
})

// Monitor the local storage (for cross-tab changes)
window.addEventListener('storage', () => {
  hydrations.forEach((hyd: any) => {
    hyd.rehydrate().then(() => console.log('Updated from browser listener'));
  });
});

// Wait until hydrations to render application
Promise.all(hydrations).then(() => {
  ReactDOM.render((
    <React.Fragment>
      <IntlProvider locale='en'>
        <LocaleProvider locale={pt_BR}>
          <Provider rootStore={store}>
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </Provider>
        </LocaleProvider>
      </IntlProvider>
    </React.Fragment>
  ), document.getElementById('root'));
})
