import { Model, Attr, HasMany } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import Organization from './Organization';

@Model({ keyCase: { server: "snake", client: "snake" } })
export default class OrganizationGroup extends ApplicationRecord {
  static jsonapiType = 'organization_groups'

  /* Attributes */
  @Attr({ persist: false }) id!: string;
  @Attr() name!: string;
  @Attr() description!: string;

  @Attr({ persist: false }) created_at!: string;

  /* Associations */
  @HasMany('Organization') organizations!: Organization[];
}
