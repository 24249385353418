import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import Content from '../Content/Content';
import { Empty, Button } from 'antd';
import { Link } from 'react-router-dom';

const SelectOrganization = () => {
  return (
    <>
      <PageHeader pageTitle="Bem-vindo ao AcerteJá" />
      <Content>
        <div className="antd-ns">
          <Empty
            description={
              <span>
                Selecione uma organização para poder continuar utilizando o painel.
              </span>
            }
          />
        </div>
      </Content>
    </>
  )
}

export default SelectOrganization;
