import React from 'react';
import { settings } from 'carbon-components';
import { Link } from 'react-router-dom';
import cx from 'classnames';
const { prefix: selectorPrefix } = settings;


const HeaderName = ({
  children,
  prefix,
  to,
  ...rest
}: any) => {
  const className = cx(`${selectorPrefix}--header__name`);

  return (
    <Link {...rest} className={className} to={to}>
      {prefix && (
        <>
          <span className={`${selectorPrefix}--header__name--prefix`}>
            {prefix}
          </span>
          &nbsp;
        </>
      )}
      {children}
    </Link>
  );
}

export default HeaderName;
