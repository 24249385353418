import React from 'react';
import { settings } from 'carbon-components';
import { NavLink } from 'react-router-dom';
const { prefix } = settings;

const HeaderMenuItem = function HeaderMenuItem(props: any) {
  const { children, to, ...rest } = props;
  return (
    <li>
      <NavLink className={`${prefix}--header__menu-item`} to={to} {...rest} role="menuitem">
        <span className={`${prefix}--text-truncate--end`}>
          {children}
        </span>
      </NavLink>
    </li>
  )
}

export default React.forwardRef((props: any, ref) => {
  return <HeaderMenuItem {...props} />;
});
