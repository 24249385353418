import React from 'react';

type Props = {
  children?: any;
  withoutGrid?: boolean;
  style?: any;
}

const Content = ({children = null, withoutGrid = false, style = {}}: Props) => {
  return (
    <div className={`page-content ${withoutGrid ? 'page-content-with-sidebar' : 'bx--grid'}`} style={style}>
      {children}
    </div>
  )
}

export default Content;
