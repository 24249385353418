import { Model, Attr, BelongsTo } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import Organization from './Organization';
import User from './User';

@Model({ keyCase: { server: "snake", client: "snake" } })
export default class OrganizationUser extends ApplicationRecord {
  static jsonapiType = 'organization_users'

  /* Attributes */
  @Attr({ persist: false }) id!: string;
  @Attr() role!: string;
  @Attr() user_id!: string;
  @Attr() organization_id!: string;
  @Attr({ persist: false }) created_at!: string;
  @Attr({ persist: false }) updated_at!: string;

  /* Associations */
  @BelongsTo('Organization') organization!: Organization;
  @BelongsTo('User') user!: User;
}
